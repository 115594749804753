<template>
	<v-app>
		<v-main>
			<div class="home">
				<v-container class="d-flex flex-column align-center">
					<v-img src="@/assets/images/logo.svg" class="logo pa-0" contain/>
					<v-img v-if="$vuetify.theme.dark" src="@/assets/images/group-dark.svg" class="group" contain/>
					<v-img v-else src="@/assets/images/group.svg" class="group" contain/>

					<div class="home__squares">
						<v-row>
							<!--              <v-col v-for="(item, index) of items" :key="'item-mobile' + index" cols="6" md="3"
												 class="d-md-none pa-2 pa-lg-3">
											<div class="home__mobile-item">
											  <div class="board pa-3 pr-2 pa-4 pa-lg-7" :class="'&#45;&#45;' + (index+1)" :style="item.styles">
												<div class="board__top">
												  <a :href="item.link" target="_blank">
													<h2 v-html="item.title"></h2></a>
												  <div v-if="item.title2" v-html="item.title2"></div>
												</div>
												<div v-html="isWindowSM?item.subtitle:item.subtitleXS || item.subtitle"></div>
											  </div>
											</div>
										  </v-col>-->
							<v-col v-for="(item, index) of items" :key="'item' + index" cols="6" md="3"
								   class="pa-2 pa-lg-3">
								<a :href="item.link" target="_blank" class="home__squares-link">
									<div class="board pa-3 pr-2 pa-4 pa-lg-7" :class="'--' + (index+1)"
										 :style="item.styles">
										<div class="board__top">
											<h2 v-html="item.title"></h2>
											<div v-if="item.title2" v-html="item.title2"></div>
										</div>
										<div class="board__subtitle"
											 v-html="isWindowSM?item.subtitle:item.subtitleXS || item.subtitle"></div>
									</div>
								</a>
							</v-col>

							<v-col cols="12" class="languages d-flex justify-center">
								<div @click="onLang" class="mr-1"
									 :class="!isLanguageEng?'active':'not-active'">рус
								</div>
								<div @click="onLang" class="ml-1"
									 :class="isLanguageEng?'active':'not-active'">eng
								</div>
							</v-col>
							<v-col cols="12" class="d-flex justify-center">
								<div class="size1">Vitrulux © 2004 - {{ year }}</div>
							</v-col>
						</v-row>
					</div>
				</v-container>
			</div>
		</v-main>
	</v-app>
</template>

<script>
const HOUR_DAY_START = 6;
const HOUR_DAY_STOP = 20;
const THEME_CHANGER_INTERVAL = 30000;

export default {
	name: 'App',
	data() {
		return {
			rusItems: [
				{
					title: 'LIGHTING',
					subtitle: 'Все виды современных систем освещения',
					link: 'https://lighting.vitrulux.com/',
				},
				{
					title: 'SMART POLES',
					subtitle: 'Основной элемент инфраструктуры <br/>Умного города',
					link: 'http://vitrunet.com/',
				},
				{
					title: 'MOBILE',
					subtitle: 'Современный взгляд на&nbsp;системы беспроводной связи и IoT',
					subtitleXS: 'Новый взгляд на&nbsp;системы <br/>беспроводной <br/>связи и IoT',
					link: 'http://vitrunet.com',
				},
				{
					title: 'LABS',
					subtitle: 'Научный подход <br/>к улучшению <br/>городской среды',
					link: 'https://lighting.vitrulux.com/',
				},
				{
					title: 'ROBOTICS',
					subtitle: 'Системы автоматического транспорта и доставки',
					link: 'https://ru.robotics.vitrulux.com',
				},
				{
					title: 'ENGINEERING',
					subtitle: 'Разработка <br/>и сопровождение инфраструктурных проектов',
					link: 'https://lighting.vitrulux.com/',
				},
				{
					title: 'VITRUVOX',
					subtitle: 'Всё для современной аналоговой <br/>звукозаписи',
					link: 'https://vitruvox.com/',
				},
				/*{
					title: 'Mobile <br class="d-none d-md-inline"/>World<br/>Congress <br class="d-none d-lg-inline" />24',
					subtitle: '26-29 Feb 2024 <br/>Hall 4. Stand 4F10_Vitrulux <br class="d-none d-sm-inline" />Barcelona',
					// subtitle: 'Присоединяйтесь к нам Vitrulux_4F10 from <br/>27 feb – 2 mar',
					link: 'https://www.mwcbarcelona.com/exhibitors/vitrulux',
				},*/
			],
			engItems: [
				{
					title: 'LIGHTING',
					subtitle: 'The full range <br/>of modern lighting systems',
					link: 'https://en.lighting.vitrulux.com/',
				},
				{
					title: 'SMART POLES',
					subtitle: 'The core element <br/>of Smart City',
					link: 'http://vitrunet.com/en/',
				},
				{
					title: 'MOBILE',
					subtitle: 'The new look of <br/>wireless & IoT connectivity',
					link: 'http://vitrunet.com/en/',
				},
				{
					title: 'LABS',
					subtitle: 'R&D for better life <br/>in the city',
					link: 'https://en.lighting.vitrulux.com/',
				},
				{
					title: 'ROBOTICS',
					subtitle: 'Automated transport <br/>& delivery systems <br/>in the metropolis',
					link: 'https://robotics.vitrulux.com',
				},
				{
					title: 'ENGINEERING',
					subtitle: 'Design & implementation <br/>of infrastructural projects',
					link: 'https://en.lighting.vitrulux.com/',
				},
				{
					title: 'VITRUVOX',
					subtitle: 'Everything about <br/>clear analogue sound',
					link: 'https://vitruvox.com/',
				},
				/*{
					title: 'Mobile <br class="d-none d-md-inline"/>World<br/>Congress <br class="d-none d-lg-inline" />24',
					subtitle: '26-29 Feb 2024 <br/>Hall 4. Stand 4F10_Vitrulux <br class="d-none d-sm-inline" />Barcelona',
					link: 'https://www.mwcbarcelona.com/exhibitors/vitrulux',
				},*/
			],
			isWindowSM: false,
			isLanguageEng: false,
			themeChangerInterval: null
		}
	},
	computed: {
		year() {
			return new Date().getFullYear();
		},
		items() {
			if (this.isLanguageEng) return this.engItems;
			else return this.rusItems;
		},
	},
	methods: {
		initLang() {
			this.isLanguageEng = !!window.location.hostname.match(/^en\./);
		},
		onLang() {
			this.isLanguageEng = !this.isLanguageEng;
			if (this.isLanguageEng) {
				window.location.href = 'https://en.vitrulux.com/';
			} else {
				window.location.href = 'https://vitrulux.com/';
			}
		},
		determineWindowWidth() {
			this.isWindowSM = window.innerWidth >= 1000;
		},
		checkThemeNeedsChange() {
			console.log('checkThemeNeedsChange');
			const time = new Date();
			const hours = time.getHours();
			const isDark = hours >= HOUR_DAY_STOP || hours < HOUR_DAY_START;
			//console.log('dark', this.$vuetify.theme.dark, isDark);
			if (this.$vuetify.theme.dark === isDark) return;

			this.$vuetify.theme.dark = isDark;
			console.log('theme changed to ' + (isDark ? 'dark' : 'light'));
		},
		initThemeChanger() {
			this.themeChangerInterval = setInterval(this.checkThemeNeedsChange, THEME_CHANGER_INTERVAL);
		},
		deInitThemeChanger() {
			clearInterval(this.themeChangerInterval);
			this.themeChangerInterval = null;
		}
	},
	created() {
		this.checkThemeNeedsChange();
		this.initThemeChanger();
	},
	mounted() {
		this.determineWindowWidth();
		this.initLang();
	},
	destroyed() {
		this.deInitThemeChanger();
	}
};
</script>

<style lang="scss">
.home {
	padding-top: 14px;
	min-height: 100vh;
	@include up($md) {
		padding-top: 50px;
	}

	.container {
		@include up($md) {
			min-height: 808px;
		}
	}

	a {
		text-decoration: none !important;
		color: unset !important;
	}

	&__mobile-item {
		@include transition();

		&:hover, &:active {
			transform: scale(1.05);
			@include up($sm) {
				transform: scale(1.03);
			}
			//box-shadow: 0 0 20px rgb(0 0 0 / 35%);
		}
	}

	&__squares {
		max-width: 328px !important;
		@include up($sm) {
			max-width: 396px !important;
		}
		@include up($md) {
			max-width: 921px !important;
		}
		@include up($lg) {
			max-width: 1260px !important;
		}

		&-link {
			display: block;
			overflow: hidden;
			@include transition();

			&:hover, &:active {
				transform: scale(1.02);
				//box-shadow: 0 0 20px rgb(0 0 0 / 35%);
			}
		}
	}

	.logo {
		width: 130px !important;
		margin-bottom: 15px;
		@include up($md) {
			width: 200px !important;
			max-width: 200px !important;
			max-height: 36px !important;
			margin-bottom: 20px;
		}
	}

	.group {
		width: 45px !important;
		margin-bottom: 30px;
		@include up($md) {
			width: 110px !important;
			max-width: 110px !important;
			max-height: 19px !important;
			margin-bottom: 50px;
		}
	}

	.board {
		height: 156px;
		position: relative;
		color: $light-grey;
		@include transition();
		@include up($sm) {
			height: 190px;
		}
		@include up($md) {
			height: 214px;
		}
		@include up($lg) {
			height: 295px;
		}

		&__top {
			height: 48%;
			@include up($md) {
				height: 60%;
			}
		}

		&.--1 {
			background-color: #1E1E1E;
			//background-image: url('~@/assets/images/items/1.png');
			background-position: center top;
			background-size: cover;
		}

		&.--2 {
			background-color: #3C3C3C;
			//background-image: url('~@/assets/images/items/2.png');
			background-position: center top;
			background-size: cover;
		}

		&.--3 {
			background-color: #5B5B5B;
			//background-image: url('~@/assets/images/items/3.png');
			background-position: center top;
			background-size: cover;
		}

		&.--4 {
			background-color: #797979;
			//background-image: url('~@/assets/images/items/4.png');
			background-position: center top;
			background-size: cover;
		}

		&.--5 {
			background-color: #979797;
			//background-image: url('~@/assets/images/items/5.png');
			background-position: center top;
			background-size: cover;
			color: $black;
		}

		&.--6 {
			background-color: #B6B6B6;
			//background-image: url('~@/assets/images/items/6.png');
			background-position: center top;
			background-size: cover;
			color: $black;
		}

		&.--7 {
			background-color: #D4D4D4;
			//background-image: url('~@/assets/images/items/7.png');
			background-position: center top;
			background-size: cover;
			color: $black;
		}

		&.--8 {
			background-color: #3A8CDE;
			//background-image: url('~@/assets/images/items/8.png');
			background-position: center top;
			background-size: cover;
			color: $white;
			padding-right: 16px !important;
			@include up($lg) {
				padding-right: 28px !important;
			}

			.board {
				&__top {
					@include up($lg) {
						height: 70%;
					}

					h2 {
						line-height: 85%;
						@include up($lg) {
							line-height: 87%;
						}
						text-transform: unset;
					}
				}

				&__subtitle {
					text-align: end;
				}
			}
		}
	}

	.languages {

		margin-top: 10px;
		@include up($md) {
			margin-top: 26px;
		}

		& > div {
			@include transition;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}
	}

	.active {
		opacity: 1;
	}

	.not-active {
		opacity: 0.6;
	}
}
</style>
